<template>
  <div>
    <div class="mb-1">
      <validation-observer tag="form" ref="filterOwners" v-slot="{ invalid }" class="list-filters">
        <b-form @submit.prevent="searchByFilter">
          <b-row>
            <b-col cols="12">
              <div class="d-flex justify-content-between">
                <h4>Filtros</h4>
                <div v-if="owners.length">
                  <b>Total de propietarios: </b> <b-badge variant="success" >  {{owners.length}} </b-badge>
                </div>
              </div>
            </b-col>
            <b-col md="">
              <validation-provider rules="" name="resort">
                <b-form-group slot-scope="{ valid, errors }">
                  <label>Resort</label>
                  <b-form-select
                    :state="errors[0] || !valid ? false : null"
                    v-model="selectedResort"
                    :clearable="false"
                  >
                    <option :value="null">Seleccione una opción</option>
                    <option v-for="h in hotels" :key="h.id" :value="h.id">
                      {{ h.name }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="">
              <validation-provider rules="" name="vivienda">
                <b-form-group slot-scope="{ valid, errors }">
                  <label>Vivienda</label>
                  <b-form-input
                    type="number"
                    :state="errors[0] || !valid ? false : null"
                    v-model="selectedVivienda"
                    placeholder="Inserte una vivienda"
                  />
                  <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="">
              <validation-provider rules="" name="Nombre o apellido">
                <b-form-group slot-scope="{ valid, errors }">
                  <label>Nombre o apellido</label>
                  <b-form-input
                    v-model="name"
                    :state="errors[0] || !valid ? false : null"
                    placeholder="Nombre o apellido"
                  />
                  <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="">
              <validation-provider rules="" name="Correo">
                <b-form-group slot-scope="{ valid, errors }">
                  <label>Correo</label>
                  <b-form-input
                    v-model="email"
                    :state="errors[0] || !valid ? false : null"
                    placeholder="mail@domain.com"
                  />
                  <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="">
              <validation-provider rules="" name="Código">
                <b-form-group slot-scope="{ valid, errors }">
                  <label>Código</label>
                  <b-form-input
                    v-model="code"
                    :state="errors[0] || !valid ? false : null"
                    placeholder="Código del propietario"
                  />
                  <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" class="text-right">
              <div>
                <b-button
                  variant="warning"
                  class="mr-1"
                  @click="resetFilterOwners"
                > Reset </b-button>
                <b-button
                  type="submit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  :disabled="invalid || isLoadingData"
                > Buscar
                </b-button>
              </div>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>
    </div>

    <b-row>
      <b-col md="2" v-if="owners.length">
        <label>Numero de registros</label>
        <v-select
          v-model="porPagina"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector"
          @input="changePerPage"
        />
      </b-col>
      <b-col md="4" v-if="owners.length">
        <label>Buscar en tabla</label>
        <div class="d-flex">
          <b-form-input
            v-model="filter.queryCustomers"
            class="mr-1"
            placeholder="Buscar..."
            type="search"
          />
        </div>
      </b-col>
      <b-col class="text-right mt-1" align-self="center" v-if="can('fivesclub_owners_show_owners_create_buttons')">
        <b-button
          :to="{name: 'customers-create'}"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          v-b-tooltip.hover.left="'Dar de alta propietario'"
        > <feather-icon icon="PlusIcon" style="color: white" />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { filtroOwners } from "@/data/fivesClub";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { acl } from "@/modules/auth/mixins/acl"

export default {
  mixins: [acl],
  directives: {
    Ripple,
  },
  props: {
    perPage: {
      type: Number,
      required: true,
    },
    perPageOptions: {
      type: Array,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
    isLoadingData: {
      type: Boolean,
      required: true
    }
  },
  components: {
    vSelect,
  },
  data() {
    return {
      subcondosfiltered: null,

      porPagina: this.perPage,
      statusFilter: null,

      name: null,
      code: null,
      email: null,
      selectedFilter: null,
      selectedResort: null,
      selectedVivienda: null,
      condoInfoRequired: false
    };
  },
  computed: {
    ...mapState('fivesClubCatalogs', ['filter', 'condos', 'subCondos','owners' ]),
    ...mapState('start',['hotels']),
  },
  methods: {
    ...mapMutations('fivesClubCatalogs', ['setQueryFilter','setOwners']),
    changePerPage(perPage) {
      this.$emit('change-per-page', perPage);
      this.porPagina = perPage;
    },
    resetFiltrado(){
      this.Filtrado = null
      this.selectedResort = null
      this.selectedVivienda = null
    },
    resetFilterOwners(){
      Object.assign(this.$data, this.$options.data())
      this.$refs.filterOwners.reset()
      this.porPagina = 10
      this.$emit('reset-filters')
    },
    searchByFilter() {
      const payload = {
        Name: !!this.name ? this.name : null,
        Code: !!this.code ? this.code : null,
        EmailOwner: !!this.email ? this.email : null,
        IdResorts: !!this.selectedResort ? this.selectedResort : null,
        Number: !!this.selectedVivienda ? this.selectedVivienda : null,
      }

      this.$emit("look-per-filter", payload);
    }
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.isCentered {
  align-content: center;
  align-items: center;
}
.isSpinner {
  padding: 2rem;
}

.paddler {
  padding-left: 1rem;
}
.mt-custom {
  margin-top: 2rem;
}
</style>