<template>
  <div>
    <Filters
      :perPage="perPage"
      :perPageOptions="perPageOptions"
      :searchQuery="searchQuery"
      :isLoadingData="isLoadingOwners"
      @change-per-page="changePerPage"
      @search-in-table-records="searchInTableRecords"
      @look-per-filter="filteredSearch"
      @reset-filters="resetData"
    />
    <br>
    <TableCustomers
      v-if="ownersData.length > 0 && !isLoadingOwners"
      :owners="ownersData"
      :tableColumns="tableColumns"
      :sortBy="sortBy"
      :isSortDirDesc="isSortDirDesc"

      @set-tab-index="setTabIndex"
    />
    <div class="isCentered isSpinner text-center" v-if="isLoadingOwners">
      <b-spinner class="isCentered" label="Spinning"></b-spinner> <br>
      Cargando Propietarios
    </div>
    <div class="" v-if="!owners.length && !isLoadingOwners && afterSearch">
      <b-alert variant="warning" :show="true" class="mb-0">
        <div class="alert-body"> <feather-icon icon="InfoIcon" class="mr-50" />
          <span><strong>¡Sin registros!</strong> No se encontraron propietarios.</span>
        </div>
      </b-alert>
    </div>
    <div class="" v-else-if="!owners.length && !isLoadingOwners">
      <b-alert variant="warning" :show="true" class="mb-0">
        <div class="alert-body"> <feather-icon icon="InfoIcon" class="mr-50" />
          <span>Haga una busqueda mediante el filtro</span>
        </div>
      </b-alert>
    </div>

    <PaginationTable
      :currentPage="currentPage"
      :totalRows="owners.length"
      :perPage="perPage"
      @change-page="changePage"
      v-if="owners.length"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import Filters from '@/modules/fivesClub/components/catalogs/customers/Filters'
import TableCustomers from '@/modules/fivesClub/components/catalogs/customers/TableCustomers'
import PaginationTable from '@/modules/fivesClub/components/catalogs/customers/PaginationTable'
import { ownerType } from '@/data/fivesClub'

export default {
  async created(){
    if (this.$route.params.reloadOwners && this.requestOwners) await this.filteredSearch(this.requestOwners)
  },
  components:{
    TableCustomers,
    PaginationTable,
    Filters
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      perPageOptions: [5, 10, 15, 20, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      statusFilter: null,
      isLoadingOwners: false,
      afterSearch: false,
      tableColumns: [
        { key: 'code', label: 'Código' },
        { key: 'name', label: 'Nombre' },
        { key: 'lastname', label: 'Apellido' },
        { key: 'phone', label: 'Teléfono' },
        { key: 'email', label: 'Correo electrónico' },
        { key: 'typeOwner', label: 'Tipo de propietario', formatter: value => {
          return ownerType.find(type => type.id == value)?.name || ''
        }},

        { key: 'status', label: 'Status', class: 'text-center' },
        { key: 'actions', label: 'Acciones', class: 'text-center' },
      ]
    }
  },
  computed: {
    ...mapState('fivesClubCatalogs', ['owners', 'requestOwners']),
    ...mapGetters('fivesClubCatalogs', ['filteredOwners']),

    ownersData(){
      if (this.filteredOwners?.length) return this.filteredOwners.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
      else return []
    },
  },
  methods: {
     ...mapMutations('fivesClubCatalogs', [ 'setOwners','setRequestOwners']),
    ...mapActions('fivesClubCatalogs', ['fetchOwners']),
    changePage(page){
      this.currentPage = page
    },
    changePerPage(perPage){
      this.perPage = perPage
    },
    searchInTableRecords(string){
      this.searchQuery = string
    },
    async filteredSearch(payload){
      this.setOwners([])
      this.afterSearch = false
      this.isLoadingOwners = true
      this.setRequestOwners(payload)
      const owners = await this.fetchOwners( payload )
      this.setOwners( owners)
      this.afterSearch = true
      this.isLoadingOwners = false
    },
    resetData(){
      this.setOwners( [] ),
      this.afterSearch = false
    },
    setTabIndex(index){
      this.$emit('set-tab-index', index)
    }
  },
};
</script>

<style lang="scss" scoped>

@media (max-width: 1280px) {
.b-table {
  max-height: 369px;
}
.b-table th  {
  padding: 10px 10px 10px 10px !important;
  font-size: 10px;
}
.b-table th div {

  font-size: 10px;
}
.b-table td {
  padding: 5px !important;
  font-size: 11px;
}
.buttonInfo button{
  padding: 9px;
}

.buttonInfo {
 display: table-cell;
}
}
</style>
